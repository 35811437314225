import { AppThunk } from '../store'
import { ActionType } from '../types/actions/analytics'
import { logoutUser } from './user'
import {getAnalytics} from "../api/analyticsAPI";
import {Analytics} from "../types/models/analytics";


const getAnalyticsAction = (): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.ANALYTICS_LOADING
    })
    getAnalytics().then((response) => {
        const analyticsResponse = response.data as Analytics
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.ANALYTICS_GETS,
            data: analyticsResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        if (exception.response.status === 401)
            dispatch(logoutUser())
        else
            dispatch({
                loading: false,
                type: ActionType.ANALYTICS_ERROR,
                ...exception.response.data
            })
    });
}

export { getAnalyticsAction }

