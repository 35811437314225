import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import {Referral} from "../models/referral";

export enum ActionType{
    REFERRAL_LOADING   = 'REFERRAL_LOADING',
    REFERRAL_GETS      = 'REFERRAL_GETS',
    REFERRAL_ERROR     = 'REFERRAL_ERROR'
}

interface actionLoading
{
    type: ActionType.REFERRAL_LOADING
}

interface actionGets
{
    type: ActionType.REFERRAL_GETS
}

interface actionError
{
    type: ActionType.REFERRAL_ERROR
}

export type Action = actionLoading | actionGets | actionError;

export type State = reducerState & apiResponse<Referral[]>;

export const initialState: State =
{
    type: undefined,
    data: undefined,
    loading: false,
    success: true,
    errors: [],
}
