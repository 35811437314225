import { useEffect, useState } from 'react'
import {  Table } from "antd"
import { Helmet } from 'react-helmet'

import useVPNBot from '../../../hooks/useVPNBot'
import Spinner from '../../Spinner'


const ReferralsTab = () =>
{

    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { usePagination, useReferral } = useVPNBot()
    const [dataSource, setDataSource] = useState<any[]>()
    const {getReferrals, referrals, loading, meta} = useReferral
    const {getPagination, setPagination} = usePagination

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        const newDataSource: Array<any> = [];
        referrals?.map((referral, index) => {
            newDataSource.push({
                key:             index,
                id:              referral.id,
                chatId:          referral.chat_id,
                nickname:        referral.name || '-',
                referralsCount:  referral.referrals_count,
                balance:         referral.balance,
                refererReferral: referral.referrer || '-',
            })
        })
        setDataSource(newDataSource)
    }, [referrals])

    useEffect(() => {
        getReferrals()
    }, [])

    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const handleChange = (pagination: any) =>
    {
        setPagination(pagination.current)
        getReferrals(pagination.current)
    }

    //-------------------------------------------------------------------------------
    //                                  TABLE COLUMNS
    //-------------------------------------------------------------------------------

    const columns = [
        {
            title: 'Referral ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Telegram ID',
            dataIndex: 'chatId',
            key: 'chatId',
        },
        {
            title: 'Telegram nickname',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: 'Referrals count',
            dataIndex: 'referralsCount',
            key: 'referralsCount',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'Referer referral',
            dataIndex: 'refererReferral',
            key: 'refererReferral',
        },
    ]

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Referrals - VPNBot Admin</title>
            </Helmet>
            {
                !loading ?
                    <Table dataSource={dataSource} columns={columns} onChange={handleChange} pagination={meta?.last_page === 1 ? false : {total: meta?.total, pageSize: meta?.per_page, current: getPagination()}}/>
                :
                    <div className='flex justify-items-center items-center flex-col w-full'>
                        <Spinner/>
                    </div>
            }
        </>
    )
}

export default ReferralsTab
