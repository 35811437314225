import { Button, Col, Form, Input, Modal, Row, Select, Space } from "antd"
import Item from '../../../types/models/item'
import Server from '../../../types/models/server'
import useVPNBot from "../../../hooks/useVPNBot"
import { useEffect, useState } from "react"
import Client from "../../../types/models/client"

interface ISource
{
    name: Client['name'],
    item: Item['name'],
    server: Server['name']
}

interface IProps
{
    title: string,
    buttonTitle: string,
    show: boolean,
    handleShow: ((state: boolean) => any)
    source: ISource,
    handleSource: ((name: string, value: string) => any),
    handleApply: (() => void)
}


const UsersModal = ({title, buttonTitle, show, handleShow, source, handleSource, handleApply}: IProps) =>
{
    const { useItem, useServer } = useVPNBot()
    const { items, getItems } = useItem
    const { servers, getServers } = useServer
    const { Option } = Select
    const [form] = Form.useForm()
    const [lastItem, setLastItem] = useState(source.item)

    useEffect(getItems, [])
    useEffect(() => {
        form.setFieldsValue(source)
        if (source.item !== lastItem)
        {
            getServers(source.item)
            setLastItem(source.item)
            handleSource('server', '')
        }
    }, [source])

    return (
        <Modal
            title={title}
            centered
            open={show}
            onOk={form.submit}
            onCancel={() => handleShow(false)}
            footer={[
                <Button key="cancel" onClick={() => handleShow(false)}>
                    Cancel
                </Button>,
                <Button form={title} key="submit" htmlType="submit">
                    {buttonTitle}
                </Button>
            ]}
        >
            <Form
                name={title}
                autoComplete="off"
                layout="vertical"
                id={title}
                form={form}
                onFinish={handleApply}
                initialValues={source}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Username"
                            name="name"
                            rules={[{ required: true, message: 'Please input username' }]}
                        >
                            <Input name='name' value={source.name} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Item"
                            name="item"
                        >
                            <Select
                                value={{value: source.item, label: source.item}}
                                options={items?.map((item: Item) =>{ return {value: item.name, label: item.name}})}
                                onChange={(value: any) => handleSource('item', value)}
                                loading={useItem.loading}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Server"
                            name="server"
                        >
                            <Select
                                value={{value: source.server, label: source.server}}
                                onChange={(value: any) => handleSource('server', value)}
                                loading={useItem.loading || useServer.loading}
                            >
                                {servers?.map((server: Server, id: number) =>
                                    <Option value={server.name} text={server.name} disabled={server.available === 0}>
                                        <span>{server.name}</span><span className="float-right">{server.available === -1 ? '∞' : server.available}</span>
                                    </Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>)
}

export default UsersModal