import { UserOutlined, GiftOutlined, GlobalOutlined, DatabaseOutlined, WalletOutlined, TeamOutlined, LineChartOutlined } from '@ant-design/icons'
import TabItem from '../../types/models/tabItem'

const NavItems = (): TabItem[] => {

    const getItem = (
        label:      TabItem["label"],
        key:        TabItem["key"],
        icon?:      TabItem["icon"],
        link?:      TabItem["link"],
    ):TabItem => {
        return {
            key,
            icon,
            label,
            link
        } as TabItem;
    }

    return [
        getItem('Clients', '1', <UserOutlined />, '/clients'),
        getItem('Items', '2', <DatabaseOutlined />, "/items"),
        getItem('Payments', '3', <WalletOutlined /> ,"/payments"),
        getItem('Promocodes', '4', <GiftOutlined />, "/promocodes"),
        getItem('Servers', '5', <GlobalOutlined />, "/servers"),
        getItem('Referrals', '6', <TeamOutlined />, "/referrals"),
        getItem('Analytics', '7',  <LineChartOutlined />, "/analytics"),
    ];
}

export default NavItems
