import {AnalyticsTariff, AnalyticsTariffStatistic, AnalyticsTariffStatisticSection} from "../../types/models/analytics";
import {ComponentProps, useCallback, useMemo} from "react";
import {ChartData} from "chart.js/auto";
import {Pie} from "react-chartjs-2";

interface TariffPieChartProps {
    statistic: AnalyticsTariffStatistic
    title: string
}

export const TariffPieChart = ({statistic, title}: TariffPieChartProps) => {
    const statisticSections = useMemo(() => {
        return Object.keys(statistic) as AnalyticsTariffStatisticSection[]
    }, [statistic])


    const getValuesBySection = useCallback((statisticSection: AnalyticsTariffStatisticSection, path: keyof AnalyticsTariff) => {
        const targetValue = statistic[statisticSection][path]
        if (!targetValue || typeof targetValue === "string") {
            return 0
        }

        return targetValue
    }, [statistic])

    const getTitleBySection = useCallback((statisticSection: AnalyticsTariffStatisticSection) => {
        const targetValue = statistic[statisticSection]?.title
        if (!targetValue) {
            return statisticSection
        }

        return targetValue
    }, [statistic])

    const priceValuesBySection = useMemo(() => {
        return statisticSections.map((statisticSection) => {
            return getValuesBySection(statisticSection, 'price')
        })
    }, [statisticSections, getValuesBySection])

    const chartData = useMemo((): ChartData<"pie"> => {
        return {
            labels: statisticSections.map((statisticSection) => {
                return getTitleBySection(statisticSection)
            }),
            datasets: [
                {
                    data: statisticSections.map((statisticSection) => {
                        return getValuesBySection(statisticSection, 'count')
                    }),
                },
            ],
        }
    }, [statisticSections, getValuesBySection])

    const chartOptions = useMemo((): ComponentProps<typeof Pie>['options'] => {
        return  {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            const dataIndex = context.dataIndex
                            return `Count: ${context.formattedValue} Price: ${priceValuesBySection[dataIndex]}`;
                        }
                    }
                },
            }
        }
    }, [priceValuesBySection])

    return (
        <div className="flex-1 flex flex-col items-center justify-center">
            <span className="capitalize text-lg font-semibold">{title}</span>
            <Pie data={chartData} options={chartOptions} className="max-h-[300px]"/>
        </div>
    )
}
