import {request} from './index'
import TokenConfig from './token'

const getAnalytics = (page?: number) => {
    return request.get('analytics', {
        headers: TokenConfig().headers,
        params: {
            page: page
        }
    })
}

export { getAnalytics };
