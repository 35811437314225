import { useState, useEffect, useContext } from 'react'
import { Button, Form, Input, message } from 'antd'
import { FilterContext } from '../../../providers/FilterProvider'
import useVPNBot from '../../../hooks/useVPNBot'
import Client from '../../../types/models/client'
import UsersModal from '../../Modal/UsersModal'
import Item from '../../../types/models/item'
import Server from '../../../types/models/server'

import './index.scss'

const UsersMenu = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { useFilter } = useContext(FilterContext)
    const { useClient } = useVPNBot()
    const { getFilter, setFilter } = useFilter
    const { addClient, getClients, success, errors, loading } = useClient
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [searchName, setSearchName] = useState(getFilter('searchField') === 'name' ? getFilter('searchValue') : undefined)
    const [searchDelay, setSearchDelay] = useState<NodeJS.Timeout>()
    const [visible, setVisible] = useState(false)
    const [fields, setFields] = useState({
        name: '',
        item: '',
        server: '',
    })

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        messageApi.destroy()
        errors?.map((value: string, index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [errors])

    useEffect(() => { if (success) setVisible(false) }, [loading])


    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const onAddClient = () => {
        const client = {} as Client
        client.name = fields.name
        const item = {} as Item
        item.name = fields.item
        const server = {} as Server
        server.name = fields.server
        addClient(client, item, server)
    }

    const onFieldChange = (name: string, value: string) =>
    {
        setFields({
            ...fields,
            [name]: value
        })
    }

    //Search filter
    const onUsernameSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        setSearchName(e.currentTarget.value)
        if (searchDelay)
        {
            clearTimeout(searchDelay)
            setSearchDelay(undefined)
        }
        setSearchDelay(setTimeout((name) => {
            setSearchDelay(undefined)
            setFilter('searchField', 'name')
            setFilter('searchValue', name)
            getClients()
        }, 500, e.currentTarget.value))
    }

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------


    return <>
        {contextHolder}
        <div className='ml-auto flex items-center'>
            <span className='mr-1'>Search: <Input className='w-[270px]' onChange={onUsernameSearch} value={searchName}/></span>
            <Button type="primary" onClick={() => setVisible(true)} disabled={loading}>Add user</Button>
        </div>
        <UsersModal
            title={'Add user'}
            buttonTitle={'Save'}
            source={fields}
            show={visible}
            handleShow={(state: boolean) => setVisible(state)}
            handleSource={onFieldChange}
            handleApply={onAddClient}
        />
    </>
}

export default UsersMenu