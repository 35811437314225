import {AnalyticsDateRange, AnalyticsTariffStatistics} from "../../types/models/analytics";
import {useCallback, useMemo} from "react";
import {TariffPieChart} from "./TariffPieChart";

interface AnalyticsTariffChartsProps {
    statistics: AnalyticsTariffStatistics
}

export const AnalyticsTariffCharts = ({statistics}: AnalyticsTariffChartsProps) => {
    const dateRanges = useMemo(() => {
        return Object.keys(statistics) as AnalyticsDateRange[]
    }, [statistics])

    const getStatistics = useCallback((dateRange: AnalyticsDateRange) => {
        return statistics[dateRange]
    }, [statistics])

    return (
        <div className="flex flex-col gap-8">
            <div className="flex flex-row items-center justify-between">
                <span className="text-lg font-semibold">Tariffs</span>
            </div>
            <div className="grid grid-cols-3 grid-rows-1">
                {dateRanges.map((dateRange) => {
                    return (
                        <TariffPieChart statistic={getStatistics(dateRange)} title={dateRange} key={dateRange}/>
                    )
                })}
            </div>
        </div>
    )
}
